<template>
  <div class="separator">
    <component
      :is="headingType ? headingType : 'div'"
      class="title"
    >
      {{ title }}
    </component>
    <div
      v-if="subtitle"
      class="subtitle"
    >
    </div>
    <div v-if="link">
      <a :href="links">
        <uikit-button
          :type="btnType"
          class="button"
        >
          {{ link.text }}
        </uikit-button>
      </a>
    </div>
  </div>
</template>

<script>
import { UikitButton } from '@hz/ui-kit'

export default {
  components: {
    UikitButton,
  },
  props: {
    btnType: {
      type: String,
      default: 'primary',
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    link: {
      type: Object,
    },
    blocNumber: {
      type: String,
    },
    headingType: {
      type: String,
      validator: (value) => ['h1', 'h2', 'h3'].includes(value),
    },
  },
  computed: {
    links() {
      return `${this.link.link}?tracking=enable&positionBouton=${this.blocNumber}`
    },
  },
  methods: {
    goToLink() {
      if (this.blocNumber) {
        const tracking = useTracking()
        if (this.blocNumber === 'Bloc 3') {
          tracking.track('[GESTION_LP] Clic Bouton Abonnement')
        } else {
          tracking.track('[GESTION_LP] Clic Bouton Commencer', { positionBouton: this.blocNumber })
        }
      }
      if (this.link.redirect) {
        window.location.href = this.link.link
      } else {
        this.$router.push({ path: this.link.link })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.separator {
  background: var(--ds-color-primary-100);
  color: var(--ds-color-white-100);
  text-align: center;
  padding: 60px 15px;
  .title {
    font-size: 24px;
    font-weight: var(--ds-weight-semi-bold);
  }
  .subtitle {
    font-weight: 400;
    font-size: 18px;
    margin-top: 15px;
  }
  .button {
    margin-top: 40px;
  }

  @media @bp-desktop {
    .title {
      font-size: 32px;
    }
  }
}
</style>
